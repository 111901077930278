import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CoreRouteNames } from '../core/core.routenames';
import { AuthRepository } from '@domain/services/auth/repository/auth.repository';
import { AccountRouteNames } from '../account/account.routenames';
import { DialogLoaderComponent } from '@presentation/molecules/dialog-loader/dialog-loader.component';
import { NewsletterRepository } from '@domain/services/newsletter/repository/newsletter.respository';
import { ConfirmationService } from 'primeng/api';
import { SLVDialogKey } from '@domain/shared/slv-template-dialog-key';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    RouterOutlet,
    DialogLoaderComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  formGroup: FormGroup;
  formObject = {
    email: new FormControl('', [Validators.required, Validators.email]),
  };
  isLoadingPage = false;

  constructor(
    private router: Router,
    private authService: AuthRepository,
    private newsLetterService: NewsletterRepository,
    private confirmationService: ConfirmationService
  ) {
    this.formGroup = new FormGroup(this.formObject);
  }

  sendForm() {
    if (this.formGroup.valid) {
      this.isLoadingPage = true;
      this.newsLetterService
        .registerNewsletter(this.formGroup.value.email)
        .subscribe({
          next: () => {
            this.confirmationService.confirm({
              key: SLVDialogKey.CONFIRM,
              header: '¡Suscripción exitosa!',
              message: 'Pronto recibirás contenido exclusivo directamente en tu bandeja de entrada. ¡Mantente atento!',
              acceptLabel: 'ENTENDIDO',
            });
            this.isLoadingPage = false;
          },
          error: () => {
            this.isLoadingPage = false;
          },
        });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  goToFaq() {
    window.open(CoreRouteNames.faq.global, '_blank');
  }

  handleClickUser() {
    if (this.authService.isLogged()) {
      this.router.navigateByUrl(AccountRouteNames.myAccount.global);
    } else {
      this.router.navigateByUrl(CoreRouteNames.login.global);
    }
  }

  getIsLogged() {
    return this.authService.isLogged() && this.authService.getToken();
  }

  handleClickLogout() {
    this.authService.logout();
    this.router.navigateByUrl(CoreRouteNames.home.global);
  }

}
