import { CommonModule, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { SLVDialogKey } from '@domain/shared/slv-template-dialog-key';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

/**
 * Plantilla para los dialogos que solo tienen el boton de confirmar
 */
@Component({
  selector: 'slv-template-dialog-confirm',
  standalone: true,
  imports: [ConfirmDialogModule, ButtonModule, NgClass, CommonModule],
  templateUrl: './template-dialog-confirm.component.html',
})
export class TemplateDialogConfirmComponent {
  protected dialogKey = SLVDialogKey;

  getLinesOfText(text: string): string[] {
    return text.split('\n');
  }

}
