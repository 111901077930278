import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RecoverPasswordResetPayload,
  RecoverPasswordResetResponse,
} from '@domain/services/password-reset/dto/recover.dto';
import {
  ResetPasswordResetPayload,
  ResetPasswordResetResponse,
} from '@domain/services/password-reset/dto/reset.dto';
import {
  ValidatePasswordResetPayload,
  ValidatePasswordResetResponse,
} from '@domain/services/password-reset/dto/validate.dto';
import { PasswordResetRepository } from '@domain/services/password-reset/repository/password-reset.repository';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({ providedIn: 'root' })
export class PasswordResetRepositoryService extends PasswordResetRepository {
  urlApi = `${URL_BACKEND}/password/reset`;

  constructor(private http: HttpClient) {
    super();
  }

  override recover(data: RecoverPasswordResetPayload): Observable<RecoverPasswordResetResponse> {
    return this.http.post<RecoverPasswordResetResponse>(`${this.urlApi}/recover`, data);
  }

  override validate(data: ValidatePasswordResetPayload): Observable<ValidatePasswordResetResponse> {
    return this.http.post<ValidatePasswordResetResponse>(`${this.urlApi}/validate`, data);
  }

  override reset(data: ResetPasswordResetPayload): Observable<ResetPasswordResetResponse> {
    return this.http.post<ResetPasswordResetResponse>(`${this.urlApi}`, data);
  }
}