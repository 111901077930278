import { inject } from '@angular/core';
import { HttpErrorResponse, type HttpInterceptorFn } from '@angular/common/http';
import { AuthRepository } from '@domain/services/auth/repository/auth.repository';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { SLVDialogKey } from '@domain/shared/slv-template-dialog-key';
import { getFirstErrorMessage } from '../helpers/getFirstErrorMessage';
import { ErrorRouteNames } from '@presentation/pages/error-pages/error-pages.routenames';

export const ServiceInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthRepository);
  const confirmationService = inject(ConfirmationService);
  const router = inject(Router);

  const token: string = authService.getToken();

  let modifiedReq = req.clone();

  if ( token ) {
    modifiedReq = modifiedReq.clone({
      setHeaders: { Authorization: `Bearer ${token}` }
    });
  }

  return next(modifiedReq).pipe(
    catchError((err: HttpErrorResponse) => {
      const currentRoute = window.location.pathname;
      if (err.status === 401) {
        if (currentRoute.includes('login')){
          const message = err.error.errors.message || 'Credenciales inválidas o el cliente no está activo.';
          confirmationService.confirm({
            key: SLVDialogKey.CONFIRM,
            icon: 'close',
            rejectIcon: 'bg-red-600',
            header: 'Ocurrió un error',
            acceptLabel: 'Entendido',
            message: message,
          })
        } else {
          router.navigateByUrl(ErrorRouteNames.error_401.global);
        }
      }
      if (err.status === 403) {
        router.navigateByUrl(ErrorRouteNames.error_403.global);
      }
      if (err.status === 404) {
        router.navigateByUrl(ErrorRouteNames.error_404.global);
      }
      if (err.status === 422 ) {
        let message = getFirstErrorMessage(err.error.errors) || 'Hubo un error al procesar la información';
        confirmationService.confirm({
          key: SLVDialogKey.CONFIRM,
          icon: 'close',
          rejectIcon: 'bg-red-600',
          header: 'Ocurrió un error',
          acceptLabel: 'Entendido',
          message: message,
        })
      }
      if (err.status === 500)  {
        // Force the user to the error page, when use navigateByUrl to the error page, the page dont change
        // window.location.href = ErrorRouteNames.error_500.global;
        router.navigateByUrl(ErrorRouteNames.error_500.global);
      }
      console.log(err);
      throw err;
    })
  );
};
