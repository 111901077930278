<div
  class="flex flex-column justify-content-between"
  [style]="{ minHeight: '100vh' }"
>
  <div
    class="w-full bg-white flex justify-content-center"
    [style]="{ padding: '34px' }"
  >
    <div class="container flex justify-content-between">
      <img
        src="assets/images/logo.png"
        alt="logo.png"
        [style]="{ width: '116px', height: '32px' }"
      />
      <div class="flex flex-row align-items-center" [style]="{ gap: '18px' }">
        <p-button
          styleClass="border-none bg-transparent p-0"
          (onClick)="goToFaq()"
        >
          <span class="material-symbols-outlined text-gray-800"> help </span>
        </p-button>
        <span class="text-gray-400 text-medium">|</span>
        <p-button
          styleClass="border-none bg-transparent p-0"
          (onClick)="handleClickUser()"
        >
          <span class="material-symbols-outlined text-gray-800"> person </span>
        </p-button>
        @if(getIsLogged()) {
          <span class="text-gray-400 text-medium">|</span>
          <p-button
            styleClass="border-none bg-transparent p-0"
            (onClick)="handleClickLogout()"
          >
          <span class="material-symbols-outlined text-gray-800"> logout </span>
          </p-button>
        }
      </div>
    </div>
  </div>
  <div class="h-full">
    <router-outlet></router-outlet>
  </div>
  <div
    class="w-full bg-primary-800 flex justify-content-center container-footer"
  >
    <div
      class="container flex flex-row flex-wrap justify-content-between gap-4"
    >
      <div
        class="flex flex-column"
        [style]="{ gap: '64px', maxWidth: '360px', width: '100%' }"
      >
        <div class="flex flex-column" [style]="{ gap: '24px' }">
          <span class="font-semibold text-white" [style]="{ fontSize: '16px' }"
            >¿Quiénes somos?</span
          >
          <span class="font-medium text-sm text-justify text-white">
            Somos un sistema creado para elaborar de manera automática
            diferentes documentos legales con la finalidad de facilitar la labor
            jurídica de diferentes personas con conocimientos legales y sobre
            todo también para personas sin ningún tipo de preparación en
            derecho.
          </span>
        </div>
        <div class="flex flex-row" style="gap: 20px">
          <div
            style="
              background-image: url(/assets/svg/facebook.svg);
              width: 16px;
              height: 16px;
            "
          ></div>
          <div
            style="
              background-image: url(/assets/svg/twitter.svg);
              width: 16px;
              height: 16px;
            "
          ></div>
          <div
            style="
              background-image: url(/assets/svg/instagram.svg);
              width: 16px;
              height: 16px;
            "
          ></div>
          <div
            style="
              background-image: url(/assets/svg/sms.svg);
              width: 16px;
              height: 16px;
            "
          ></div>
        </div>
      </div>
      <form
        [formGroup]="formGroup"
        class="flex flex-column"
        [style]="{ gap: '32px', maxWidth: '360px', width: '100%' }"
        autocomplete="off"
        (ngSubmit)="sendForm()"
      >
        <div class="flex flex-column" [style]="{ gap: '16px' }">
          <span class="font-semibold text-white" [style]="{ fontSize: '16px' }"
            >Newsletter</span
          >
          <input
            type="email"
            pInputText
            placeholder="Tu correo"
            class="w-full footer-input-form"
            [class.invalid]="
              formGroup.get('email')?.invalid && formGroup.get('email')?.touched
            "
            formControlName="email"
          />
        </div>
        <p-button
          type="submit"
          styleClass="w-full bg-gray-200 justify-content-center border-noround"
          [style]="{ maxWidth: '170px', padding: '8px 10px' }"
        >
          <span class="font-medium text-primary-600">ENVIAR</span>
        </p-button>
      </form>
    </div>
  </div>
</div>
<slv-dialog-loader [visible]="isLoadingPage" />