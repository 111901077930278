import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ConfirmationService } from 'primeng/api';
import { routes } from './app.routes';
import { InfrastructureProviders } from './infraestructure/infraestructure';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ServiceInterceptor } from './core/interceptor/service.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    ConfirmationService,
    ...InfrastructureProviders,
    provideHttpClient(withInterceptors([
      ServiceInterceptor
    ])),
  ],
};
