import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IzipayFormTokenPayload, IzipayFormTokenResponse } from "@domain/services/izipay/dto/izipay-form.dto";
import { IzipayRepository } from "@domain/services/izipay/repository/izipay.repository";
import { Observable } from "rxjs";
import { URL_BACKEND } from "src/app/core/config/url";
import { objectToQueryString } from "src/app/core/helpers/convertObjectToQueryParams";

@Injectable({
  providedIn: 'root',
})
export class IzipayService extends IzipayRepository {
  urlApi = `${URL_BACKEND}/payment/izipay`;

  constructor(private http: HttpClient) {
    super();
  }

  override getFormToken(payload: IzipayFormTokenPayload): Observable<IzipayFormTokenResponse> {
    const paramas = objectToQueryString(payload)
    return this.http.get<IzipayFormTokenResponse>(`${this.urlApi}/form/token?${paramas}`);
  }

  override verifyContractPayment(payload: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/paid`, payload);
  }

}