import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerContractRepository } from '@domain/services/cutomer-contract/repository/customer-contract.repository';
import {
  UpsertCustomerContractPayload,
  UpsertCustomerContractResponse,
} from '@domain/services/cutomer-contract/dto/customer-contract-upsert.dto';
import { URL_BACKEND } from 'src/app/core/config/url';
import { ReadContractCustomerResponse } from '@domain/services/cutomer-contract/dto/read-contract-customer.dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerContractRepositoryService extends CustomerContractRepository {
  urlApi = `${URL_BACKEND}/many/contract/customer`;

  constructor(private http: HttpClient) {
    super();
  }

  override showCustomerContract(order_id: string): Observable<ReadContractCustomerResponse> {
    return this.http.get<ReadContractCustomerResponse>(`${this.urlApi}/show?order_id=${order_id}`);
  }

  override upsertCustomerContract(
    payload: UpsertCustomerContractPayload
  ): Observable<UpsertCustomerContractResponse> {
    return this.http.post<UpsertCustomerContractResponse>(
      `${this.urlApi}/upsert`, payload
    );
  }
}
