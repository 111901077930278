import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerRepository } from '@domain/services/customer/repository/customer.repository';
import {
  RegisterCustomerPayload,
  RegisterCustomerResponse,
} from '@domain/services/customer/dto/register-customer.dto';
import { URL_BACKEND } from 'src/app/core/config/url';
import { ShowCustomerResponse } from '@domain/services/customer/dto/read-customer.dto';
import {
  UpdateCustomerPayload,
  UpdateustomerResponse,
} from '@domain/services/customer/dto/update-customer.dto';
import { UpsertCustomerContractPayload } from '@domain/services/cutomer-contract/dto/customer-contract-upsert.dto';
import { SendEmailPayload } from '@domain/services/customer/dto/send-email.dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerRepositoryService extends CustomerRepository {
  urlApi = `${URL_BACKEND}/user/customer`;

  constructor(private http: HttpClient) {
    super();
  }

  override registerCustomer(
    payload: RegisterCustomerPayload
  ): Observable<RegisterCustomerResponse> {
    return this.http.post<RegisterCustomerResponse>(`${this.urlApi}`, payload);
  }

  override updateCustomerContract(
    payload: UpdateCustomerPayload
  ): Observable<UpdateustomerResponse> {
    return this.http.put<UpdateustomerResponse>(`${this.urlApi}`, payload);
  }

  override showCustomer(email: string): Observable<ShowCustomerResponse> {
    return this.http.get<ShowCustomerResponse>(`${this.urlApi}/show`, {
      params: {
        email: email,
      },
    });
  }

  override sendContractEmail(payload: SendEmailPayload): Observable<any> {
    return this.http.post(`${this.urlApi}/mail`, payload);
  }

  override setContractDataInLocalStorage(
    payload: UpsertCustomerContractPayload
  ): Observable<void> {
    localStorage.setItem('contract', JSON.stringify(payload));
    return new Observable();
  }

  override hasContractDataInLocalStorage(): boolean {
    return !!localStorage.getItem('contract');
  }

  override getContractDataInLocalStorage(): UpsertCustomerContractPayload | null {
    const contract = localStorage.getItem('contract');
    return contract ? JSON.parse(contract) : null;
  }

  override removeContractDataInLocalStorage(): void {
    localStorage.removeItem('contract');
  }
}
