import { Routes } from '@angular/router';
import { AppRouteNames } from './app.routenames';
import { LayoutComponent } from '@presentation/pages/layout/layout.component';

export const routes: Routes = [
  {
    path: AppRouteNames.core,
    component: LayoutComponent,
    loadChildren: () => import('./presentation/pages/core/core.routes').then(m => m.routes)
  },
  {
    path: AppRouteNames.account,
    component: LayoutComponent,
    loadChildren: () => import('./presentation/pages/account/account.routes').then(m => m.routes)
  },
  {
    path: AppRouteNames.error,
    component: LayoutComponent,
    loadChildren: () => import('./presentation/pages/error-pages/error-pages.routes').then(m => m.routes)
  }
];
