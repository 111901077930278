import { AuthRepositoryProvider } from "./auth/auth.provider";
import { CategoryProvider } from "./categories/category.provider";
import { ContractProvider } from "./contract/contract.provider";
import { CustomerContractProvider } from "./customer-contract/customer-contract.provider";
import { CustomerProvider } from "./customer/customer.provider";
import { FaqProvider } from "./faq/faq.provider";
import { IzipayProvider } from "./izipay/izipay.provider";
import { ManagementFileProvider } from "./management-file/management-file.provider";
import { NewsLetterProvider } from "./newsletter/newsletter.provider";
import { PasswordResetRepositoryProvider } from "./password-reset/password-reset.provider";
import { ConfigProvider } from "./config/config.provider";

export const InfrastructureProviders = [
  ...ConfigProvider,
  ...CategoryProvider,
  ...FaqProvider,
  ...ContractProvider,
  ...AuthRepositoryProvider,
  ...PasswordResetRepositoryProvider,
  ...CustomerProvider,
  ...IzipayProvider,
  ...CustomerContractProvider,
  ...NewsLetterProvider,
  ...ManagementFileProvider
];