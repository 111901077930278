import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetCategoriesResponse } from '@domain/services/categories/dto/get-categories.dto';
import { CategoryRepository } from '@domain/services/categories/repository/category.repository';
import { Observable } from 'rxjs';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class CategoryRepositoryService extends CategoryRepository {
  urlApi = `${URL_BACKEND}/taxonomy/category`;

  constructor(private http: HttpClient) {
    super();
  }

  override get(): Observable<GetCategoriesResponse> {
    return this.http.get<GetCategoriesResponse>(`${this.urlApi}/get`);
  }
}
