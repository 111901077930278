import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GetFaqsResponse } from "@domain/services/faqs/dto/get-faqs.dto";
import { FaqRepository } from "@domain/services/faqs/repository/faq.repository";
import { URL_BACKEND } from "src/app/core/config/url";

@Injectable({
  providedIn: 'root',
})
export class FaqRepositoryService extends FaqRepository {
  urlApi = `${URL_BACKEND}/utility/faq`;

  constructor(private http: HttpClient) {
    super();
  }

  get(): Observable<GetFaqsResponse> {
    return this.http.get<GetFaqsResponse>(`${this.urlApi}/get`);
  }

}