import { Injectable } from "@angular/core";
import { ConfigRepository } from "@domain/services/config/config.repository";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends ConfigRepository {
  override getKrPublicKey(): string  {
    return environment.krPublicKey;
  }
}
