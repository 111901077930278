import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TemplateDialogConfirmComponent } from '@presentation/molecules/template-dialog-confirm/template-dialog-confirm.component';
import { TemplateDialogNotificationComponent } from '@presentation/molecules/template-dialog-notification/template-dialog-notification.component';
import { TemplateDialogQuestionComponent } from '@presentation/molecules/template-dialog-question/template-dialog-question.component';
import { ConfigService } from './infraestructure/config/config.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TemplateDialogConfirmComponent,
    TemplateDialogQuestionComponent,
    TemplateDialogNotificationComponent,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private configService: ConfigService) {}

  ngOnInit() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
    script.setAttribute('kr-public-key', this.configService.getKrPublicKey());
    script.setAttribute('kr-language', 'es-ES');
    document.head.appendChild(script);
  }
}
