import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_BACKEND } from 'src/app/core/config/url';
import {
  LoginRequest,
  LoginResponse,
} from '@domain/services/auth/dto/login.dto';
import { AuthRepository } from '@domain/services/auth/repository/auth.repository';
import { Observable } from 'rxjs';
import { CoreRouteNames } from '@presentation/pages/core/core.routenames';

@Injectable({ providedIn: 'root' })
export class AuthRepositoryService extends AuthRepository {
  urlApi = `${URL_BACKEND}/auth`;

  constructor(private http: HttpClient, private router: Router) {
    super();
  }
  changeToken = new EventEmitter();

  override login(data: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${URL_BACKEND}/auth/login`, data);
  }

  override logout(): void {
    localStorage.removeItem('customer');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('logued');
    this.router.navigateByUrl(CoreRouteNames.login.global);
  }

  override getEmail(): string {
    const email = localStorage.getItem('email') || '';
    return email;
  }

  override getToken(): string {
    const token = localStorage.getItem('token') || '';
    return token;
  }

  override isLogged(): boolean {
    const logued = localStorage.getItem('logued');
    return !!logued;
  }
}
