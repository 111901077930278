import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsletterWriteResponse } from '@domain/services/newsletter/dto/repository-write.dto';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  urlApi = `${URL_BACKEND}/entity/newsletter`;

  constructor(private http: HttpClient) {}

  registerNewsletter(email: string): Observable<NewsletterWriteResponse> {
    return this.http.post<NewsletterWriteResponse>(`${this.urlApi}`, {
      email,
    });
  }

}
