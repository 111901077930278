import { Component } from '@angular/core';
import { SLVDialogKey } from '@domain/shared/slv-template-dialog-key';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgClass } from '@angular/common';

@Component({
  selector: 'slv-template-dialog-question',
  standalone: true,
  imports: [ConfirmDialogModule, ButtonModule, NgClass],
  templateUrl: './template-dialog-question.component.html',
})
export class TemplateDialogQuestionComponent {
  protected dialogKey = SLVDialogKey;
}
