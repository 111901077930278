export function objectToQueryString(obj: any, prefix = '') {
  const queryString: string = Object.keys(obj).map(key => {
      const value = obj[key];
      const encodedKey = encodeURIComponent(prefix ? `${prefix}[${key}]` : key);
      if (typeof value === 'object' && value !== null) {
          return objectToQueryString(value, encodedKey);
      }
      return `${encodedKey}=${encodeURIComponent(value)}`;
  }).join('&');
  return queryString;
}